import styled from 'styled-components'

export const ContentContainer = styled.div`

  width: 100%;
  height: 1080px;
  display: flex;
  box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2);
  position: relative;

  @media(min-width: 415px) and (max-width: 1024px){
    height: 800px;
  }

  @media(min-width: 320px) and (max-width: 414px){
    flex-direction: column;
    height: auto;
  }

`

export const TextSide = styled.div`

  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #F2F3F8; 
  text-align: left;
  align-items: center;

  & > img{
    margin-top: 20;
    z-index: 1;
    margin-left: 10%;
    /* width: ${props => props.width? props.width : 'auto'}; */
    @media(min-width: 830px) and (max-width: 1024px){
      width: 65%;
    }
    
    @media(min-width: 320px) and (max-width: 414px){
      width: 85%;
    }
  } 

  @media(max-width: 1024px){
    align-items: center;
  }

  @media(min-width: 320px) and (max-width: 414px){
    width: 100%;
  }

`

export const ImageSide = styled.div`

  width: 50%;
  display: flex;
  background-color: #F2F3F8; 
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img{
    height: ${props => props.hImg? props.hImg : '650'}px;

    @media (min-width: 1367px) and (max-width: 1440px){
      height: 500px;
    }

    @media (min-width: 1025px) and (max-width: 1366px){
      height: 400px;
    }

    @media (min-width: 835px) and (max-width: 1024px){
      height: 300px;
    }

    @media (min-width: 768px) and (max-width: 834px){
      height: 300px;
    }

    @media (min-width: 414px) and (max-width: 767px){
      height: 230px;
    }

    @media (min-width: 320px) and (max-width: 413px){
      height: 200px;
    }

  }

  @media (min-width:768px) and (max-width: 1024px){
    & > div{
      width: 75%;
    }
    align-items: center;
  }

  @media(min-width: 320px) and (max-width: 414px){
    width: 100%;
    height: 100%;
    padding-top: 36px;
    padding-bottom: 36px; 
  }

`

export const Title = styled.div`

  display: flex;
  justify-content: flex-start;
  margin-top: 36px;
  margin-bottom: 36px;
  width: 550px;
  text-transform: uppercase;

  & > span{

    color: #08C1C1; 
    font-weight: bold; 
    font-size: 48px;
  }

  @media (min-width: 415px) and (max-width: 1024px){
    width: 400px;
  }

  @media (min-width: 320px) and (max-width: 414px){
    width: 300px;
  }

`

export const SubTitle = styled.div`

  display: flex;
  justify-content: flex-start;
  margin-bottom: 36px;
  width: 550px;

  & > span{

    color: #1E3760; 
    font-weight: 500; 
    font-size: 24px;
  }

  @media (min-width: 415px) and (max-width: 1024px){
    width: 400px;
  }

  @media (min-width: 320px) and (max-width: 414px){
    width: 300px;
  }

`

export const Description = styled.div`

  display: flex;
  justify-content: flex-end;
  margin-bottom: 36px;
  width: 550px;

  & > span{

    color: #1E3760; 
    font-weight: normal; 
    font-size: 18px;
  }

  @media (min-width: 415px) and (max-width: 1024px){
    width: 400px;
  }

  @media (min-width: 320px) and (max-width: 414px){
    width: 300px;
  }

`

export const ButtonDiv = styled.div`

   display: flex;
   margin-top: 50px;
   border: 1px solid #09345C;
   box-sizing: border-box;
   border-radius: 18px;
   align-self: flex-start;
   margin-left: 200px;

   & > a{
      text-transform: uppercase;
      color: #09345C;
      padding: 10px 50px 10px 50px;
      font-size: 14px;
      text-decoration: none;
   }

   &:hover{
      cursor: pointer;
      border: 1px solid #1E3760;
      background: #22EEE1;
      & > a{
         color: #1E3760;
      }
   }

   @media (min-width: 320px) and (max-width: 1024px){
      margin-left: 0;
      align-self: center;
   }
`
import React from 'react'
import { ContentContainer, TextSide, Title, SubTitle, Description, ImageSide, ButtonDiv } from './styles.js'


function InfoExtrato(props){
   
   return(

      <ContentContainer>
         
         <ImageSide hImg={props.heightImg}>
            <img src={props.imgExtrato} alt='Graphics' />
         </ImageSide>

         <TextSide>
            <Title>
               <span><b>{props.titleExtrato}</b></span>
            </Title>
            <SubTitle>
               <span><b>{props.subTitleExtrato}</b></span>
            </SubTitle>
            <Description>
               <span>{props.descriptionExtrato}</span>
            </Description>
            <ButtonDiv>
               <a href='https://extratowowcard.wowsolution.com/' target="_blank" rel="noopener noreferrer">Consultar</a>
            </ButtonDiv>
         </TextSide>

      </ContentContainer>

   )

}

export default InfoExtrato

import React, { useState } from 'react'
import Header from '../../components/header'
import ResponsiveHead from '../../components/ResponsiveHead'
import SliderBan from '../../components/sliderBan'
import Advantages from '../../components/advantages'
import Definition from '../../components/definition'
import Graphics from '../../components/graphics'
import Benefits from '../../components/benefits'
import InfoExtrato from '../../components/infoExtrato'
import FAQ from '../../components/faq'
import BannerFoot from '../../components/bannerFoot'
import Footer from '../../components/footer'

import WowCardBanner from '../../images/wowCard/WowCardBanner.png'
import WowCardBanner2 from '../../images/wowCard/WowCardBanner2.svg'
import FirstLeft from '../../images/wowCard/FirstLeft.svg'
import SecondLeft from '../../images/wowCard/SecondLeft.svg'
import ThirdLeft from '../../images/wowCard/ThirdLeft.svg'
import FirstRight from '../../images/wowCard/FirstRight.svg'
import SecondRight from '../../images/wowCard/SecondRight.svg'
import ThirdRight from '../../images/wowCard/ThirdRight.svg'
import Cards from '../../images/wowCard/Cards.svg'
import GraphicsImg from '../../images/wowCard/GraphicsImg.svg'
import LeftCardImg from '../../images/wowCard/LeftCardImg.svg'
import CenterCardImg from '../../images/wowCard/CenterCardImg.svg'
import RightCardImg from '../../images/wowCard/RightCardImg.svg'
import ExtratoImg from '../../images/wowCard/ExtratoImg.svg'

import { useStaticQuery, graphql } from 'gatsby'

export default () =>{

   const [open, setOpen] = useState(false);

    const data = useStaticQuery(graphql`
    {
        site {
        siteMetadata {
            WowCard{
                text
            }
        }
        }
    }`)

    const slidesInfo = [
		{
			image: WowCardBanner,
			titleHead: 'Wow Card',
            descriptionHead: 'Um cartão de crédito, físico ou virtual, e as melhores mudanças no seu jeito de lidar com dinheiro! Sem se preocupar com faturas, você aproveita todas as facilidades tecnológicas que só a Wow oferece!',
            btnText: 'Eu quero!',
            urlBtn: 'https://www.wowmanager.com.br/signup'
		},
		{
			image: WowCardBanner2,
			titleHead: 'Extrato Rapido',
            descriptionHead: 'Faça a consulta de saldo e extrato de seu Wow Card, sem necessidade de cadastros. ',
            btnText: 'Consultar',
            urlBtn: 'https://extratowowcard.wowsolution.com/',
            center: true,
		}

	]

    const leftItems = [
        {
            text: "Utilize para pagar seus colaboradores, para seus funcionários em trânsito, para bonificações, comissões e para facilitar a checagem de gastos em tempo real.",
            image :{
                src: FirstLeft,
                alt: "First Left"
            }

        },
        {
            text: "Seu cartão virtual é aprovado e liberado na mesma hora!",
            image :{
                src: SecondLeft,
                alt: "Second Left"
            }

        },
        {
            text: "Cartão de crédito pré-pago internacional.",
            image :{
                src: ThirdLeft,
                alt: "Third Left"
            }

        }
    ]

    const rightItems = [
        {
            text: "Em poucos cliques, solicite o cartão, consiga sua aprovação, recarregue, bloqueie ou cancele. Facilidade e rapidez!",
            image :{
                src: FirstRight,
                alt: "First Right"
            }

        },
        {
            text: "Peça quantos cartões quiser e invista na organização financeira da sua empresa e da sua família.",
            image :{
                src: SecondRight,
                alt: "Second Right"
            }

        },
        {
            text: "Consiga recarregar seus cartões 24/7 com sua conta Wow ou pelo app!",
            image :{
                src: ThirdRight,
                alt: "Third Right"
            }

        }
    ]

    const definitionTitle = "Wow Card é..."

    const definitionSubTitle = "A evolução dos cartões que conhecemos hoje!"

    const definitionText = `Oferecemos um cartão de crédito pré-pago internacional, nos modelos físico e virtual.
    Com ele, você tem total controle e não precisa se preocupar com as faturas. 
    Você adiciona o saldo que deseja gastar e pode usar livremente, em qualquer lugar no que aceite a bandeira Mastercard. 
    Revolucionamos a forma que você bonifica seus colaboradores, fornecedores e melhoramos sua forma de lidar com sua educação financeira!`

    const graphicsTitle = [ "O melhor para você e seu negócio" ]

    const graphicSubTitle = [ "Unifique. Automatize. Surpreenda-se. Aproveite." ]

    const graphicsText = [ "Controle das movimentações realizadas. Comparativo de entradas e saídas entre períodos. Gráficos e extratos por período. Controle de saldo. Movimentações na linha do tempo. Controle de performance por cartões ou por conta. Recarga facilitada e em poucos cliques." ]

    const extratoTitle = [ "Extrato Rapido" ]

    const extratoSubTitle = [ "Você já tem seu Wow Card?" ]

    const extratoText = [ "Consulte as informações sobre saldo e extrato do seu Wow Card de forma rápida clicando no botão abaixo. Para mais opções, como bloqueio, cancelamento ou alteração de senha, acesse a plataforma Wow Solution." ]

    const cards = [
        {
            title:'Seus Cartões',
            subTitle:'Quantos cartões forem precisos',
            text:'Seja para você, sua família ou sua empresa. Peça quantos cartões quiser, sejam eles físicos ou virtuais, e acompanhe de perto todo o extrato e saldo de cada um deles.',
            img: LeftCardImg
        },
        {
            title: 'Controle Total',
            subTitle:'Extrato simples e completo',
            text:'Em poucos cliques, você tem acesso a todos seus gastos com os cartões Wow. Seja em compras online ou presenciais, com seu cartão físico ou virtual, seu controle financeiro está aqui.',
            img: CenterCardImg
        },
        {
            title: 'Sem gastos extras',
            subTitle:'Sem Faturas, sem gastos extras',
            text:'Recarregue seu cartão e não se preocupe em gastar demais! Se perdeu seu cartão, bloqueie ou transfira o saldo para sua conta Wow ou novo cartão. Facilidades com um clique.',
            img: RightCardImg
        }
    ]
  

    const faqItems = [
        {
            title: "Como eu posso recarregar meus cartões Wow?",
            text: "É muito simples! Você pode usar sua conta Wow Manager e passar o saldo diretamente para seus cartões físicos e virtuais! Funciona 24/7, seja no app ou na plataforma! Mas se preferir outras formas, você pode gerar um boleto para fazer esta recarga e pagar com a instituição financeira que preferir."
        },
        {
            title: "O cartão da Wow Card serve apenas para meu negócio?",
            text: "Não. Sabemos que a educação financeira é essencial, portanto, incentivamos que as crianças já aprendam desde cedo a organizar seus gastos, e com a Wow Card, os pequenos podem aprender a controlar seus gastos e os pais podem acompanhar esse crescimento, afinal, toda vez que os cartões são utilizados, você é notificado! Surpreendente para você, seu negócio e sua família!"
        },
        {
            title: "Posso usar meu Wow Card para compras internacionais ou streaming?",
            text: "Claro! Utilize seus cartões para qualquer tipo de compra à vista! Uma dica é utilizar o cartão virtual, que já é liberado na hora da solicitação e você não precisa esperar para poder aproveitar as vantagens de ser Wow! E se for viajar para o exterior, basta adicionar o saldo desejado e gastar sem medos!"
        }
    ]

    return(
        <>
            <Header open={open} setOpen={setOpen} currentPage='Wow Card' />
            <ResponsiveHead open={open} setOpen={setOpen} />
			<SliderBan slides={slidesInfo} />

            <Benefits benefitsCards={cards} />
            <Definition title={definitionTitle} subTitle={definitionSubTitle} text={definitionText} img={Cards} width={100} />
            <Graphics title={graphicsTitle} subTitle={graphicSubTitle} description={graphicsText} img={GraphicsImg} heightImg={500} />
            <Advantages leftItems={leftItems} rightItems={rightItems} />
            <InfoExtrato titleExtrato={extratoTitle} subTitleExtrato={extratoSubTitle} descriptionExtrato={extratoText} imgExtrato={ExtratoImg} />
            {/* <InfoExtrato imgExtrato={ExtratoImg} /> */}
            <FAQ items={faqItems}/>

            <BannerFoot>{data.site.siteMetadata.WowCard.text}</BannerFoot>

            <Footer />
        </>
    )
}